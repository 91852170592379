import React, { Fragment } from 'react';
import { RotatingLines } from "react-loader-spinner";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '../components/css/style.css';

export const xAuth="M@#weh@##dhASD2627@#2024";


/* ############ OCM service ########## */
export const service="ocm";
export const countryCode="237";
export const flag="cm";
export const lang="fr";
export const serviceId=1;
//export const baseUrl = "/ocmapi";
export const baseUrl = "https://ocm.uvcanngaming.com/ocmApi"; 

/* ############ OGB service ########## 
export const service="ogb";
export const countryCode="245";
export const flag="gw";
export const lang="pr";
export const serviceId=1;
//export const baseUrl = "/ocmapi";
export const baseUrl = "https://ogb.uvcanngaming.com/ogbApi"; */




export const brandImages = [
    "/images/brand_1.png",
    "/images/brand_2.png",
    "/images/brand_3.png",
    "/images/brand_4.png",
    "/images/brand_2.png",
    "/images/brand_5.png",
  ];

export const footerImages = [
      "/images/footer_3.png",
      "/images/footer_2.png",
      "/images/footer_1.png",
      "/images/footer_4.png",
      "/images/footer_5.png",
  ];

 
  export const Loader = () => {
    return (
      <div style={{ width: "100px", margin: "auto",}}>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="60"
          visible={true}
        />
      </div>
    )  
  
  }
  export const Notify = (props) => {
    console.log('props='+props);
    if(props.message)
    {
      if(props.type)
        toast.success(props.message,);
      else
        toast.warning(props.message,);
    }
    // inbuilt-notification
   /* toast.success("successful");
    // inbuilt-notification
    toast.info("GeeksForGeeks");
    // inbuilt-notification
    toast.error("Runtime error");
    // default notification
    toast("Hello Geeks");*/

    return (
      <ToastContainer />
    );
  }

  export const Random=()=>{
    let rnd=Math.floor(Math.random()*90000) + 10000;
    return rnd+""+Date.now();
  }
