import React, { useEffect, useState } from "react"
import "./css/winners.css"
import ReactModal from "react-modal"
import { getRanking } from "../services/user-api"
import Confetti from "react-confetti"
import { useTranslation } from "react-i18next"
import { service} from "../utils/constant"

ReactModal.setAppElement("#root")

const Winners = ({ isOpen, closeModal }) => {
  const { t } = useTranslation("global")
  const [users, setUsers] = useState([]);
  const [userRank, setUserRank] = useState([]);
  const [userId, setUserId] = useState([])
  const rewards=["25 000F","10 000F","75 00F","5 000F","2 500F","","","","",""];

  useEffect(() => {
    getRanking()
      .then((res) => {
        console.log(res)
        setUsers(res.data.data);
        setUserRank(res.data.userRank);
        setUserId(res.data.userId);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
  }

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        shouldFocusAfterRender={false}
        shouldReturnFocusAfterClose={false}
        contentLabel="Winners Dialog"
        overlayClassName="winner-dialog-overlay"
        className="winner_dialog"
      >
        <div className="winners_page">
          <section className="header_container">
            <div className="title_box">
              <img src="/images/crown.png" alt="Crown" />
              <div className="title">{t("TopWinners5")}<div className="rank">{t("YourRank")} - {userRank}
              </div></div>
            </div>
            <button className="close_btn" onClick={closeModal}>
              <i className="fas fa-xmark"></i>
            </button>
          </section>
         
           <section className="table-container">
           <table className="table">
             <thead className="thead">
               <tr>
                 <th scope="col">{t("Player")}</th>
                 <th scope="col">{t("Rank")}</th>
                 <th scope="col">{t("Time Spent")}</th>
                 {service==="ogb" && <th scope="col">{t("Rewards")}</th>}
               </tr>
             </thead>
             <tbody>
               {users.length > 0 &&
                 users.map((user, index) => {
                   return (
                     <tr key={user.id}>
                       <td>
                         <div className="player_name">
                           {/*<img src="/images/profile.png" alt="profile" />*/}
                           {service==="ogb"?<div>{user.userName.replace("245x","")}</div>:<div>{user.userName}</div>}
                         </div>
                       </td>
                       <td>{index + 1}</td>
                       <td>{formatTime(user.sou)}</td>
                       {service==="ogb" && <td>{rewards[index]}</td>}
                     </tr>
                   )
                 })}
             </tbody>
           </table>
         </section>
        
        </div>
        <Confetti
          width={
            document.getElementsByClassName("winners_page")[0]?.clientWidth
          }
          height={
            document.getElementsByClassName("winners_page")[0]?.clientHeight
          }
        />
      </ReactModal>
    </>
  )
}

export default Winners
